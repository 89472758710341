import { Box, Flex, IconButton } from '@fluidtruck/core';
import { FilterIcon, ListIcon, MapIcon, SearchIcon } from '@fluidtruck/icons';
import { ClickAwayListener } from '@material-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

import { ampli } from '@/amplitude';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from '@/base-components/Drawer';
import { MaintenanceBanner } from '@/base-components/MaintenanceBanner';
import ItemDetail from '@/components/Item/ItemDetail';
import ItemCart from '@/components/ItemCart';
import ItemContainer from '@/components/ItemContainer';
import ItemMap from '@/components/ItemMap';
import SearchSummary from '@/components/Reservation/ReservationSummary/SearchSummary';
import { MobileSearchBar } from '@/components/SearchBar/MobileSearchBar';
import { WeeklyCalculatorProvider } from '@/features/search/weekCalculator';
import { useResize, useSanctuary, useUser } from '@/hooks';
import {
  CartContext,
  ItemsContext,
  useReservationEstimate,
} from '@/lib/context';
import { heights, widths } from '@/theme';

import ItemFilter from '../ItemFilter';
import { useLeftNavMenuContext } from '../LeftNavMenu';
import * as Styles from './searchStyles';

const { topNav, searchBar, cartBar } = heights;

const {
  leftNavOpen,
  leftNavClosed,
  searchDrawer,
  searchItem,
  searchItemDefault,
} = widths;

const Search = () => {
  const router = useRouter();
  const mobileSearchOpen =
    router?.query?.isMobileAndSpecificSearchOpen === 'true' || false;

  const { t } = useTranslation(['common', 'search']);
  const { isMobile, isDesktop } = useResize();
  const [showMobileMap, setShowMobileMap] = useState(false);
  const drawerSize = isDesktop ? 'lg' : 'full';

  const { isCollapsed } = useLeftNavMenuContext();

  const gridRef = React.useRef(null);

  const {
    detailView,
    updateDetailView,
    updateSelectedItem,
    isLoading,
    setIsInitialSearch,
  } = useContext(ItemsContext);

  const [showMobileSearch, setShowMobileSearch] = useState(mobileSearchOpen);

  const { user } = useUser();
  const { cart } = useContext(CartContext);
  const { updateEstimate } = useReservationEstimate();

  const { type, detailViewId, isAvailable } = detailView;
  const showItemDetail = Boolean(detailViewId && type === 'item');
  const showReservation = Boolean(detailViewId && type === 'reservation');

  // Note: calculate the postioning of the drawers with user or leftnav
  const searchItemWidth = user ? searchItem : searchItemDefault;
  const closed = leftNavClosed + searchItemWidth;
  const open = leftNavOpen + searchItemWidth;
  const leftPosition = isCollapsed
    ? `${closed}px !important`
    : `${open}px !important`;

  const drawerContent = {
    top: {
      base: 0,
      lg: `${topNav + searchBar}px !important`,
    },
    left: {
      base: '0 !important',
      lg: user ? leftPosition : `${searchItemWidth}px !important`,
    },
    boxShadow: 'base',
    width: { base: '100% !important', lg: `${searchDrawer}px !important` },
  };

  const onDrawerClose = () => {
    updateSelectedItem(0);
    updateEstimate({ 'item-id': 0 });
    updateDetailView({ type: '', detailViewId: null });
  };

  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClick = () => {
    setShowFilter(true);
    updateDetailView({ type: '', detailViewId: null });
  };

  const { data } = useSanctuary();

  const maintenanceWindow = data?.config?.maintenanceWindow;

  const details = maintenanceWindow?.details || '';
  const startAt = maintenanceWindow?.startAt || '';
  const endAt = maintenanceWindow?.endAt || '';
  const status = maintenanceWindow?.status || '';

  const hasMaintentanceData = true;
  // const hasMaintentanceData = !!(
  //   Object.keys(maintenanceWindow || {}).length > 0
  // );

  const timezone = moment.tz.guess();

  const startDate = moment(startAt).format('M/D');
  const startTime = moment(startAt).tz(timezone).format('h:mm A z');

  const endDate = moment(endAt).format('M/D');
  const endTime = moment(endAt).tz(timezone).format('h:mm A z');

  const startString = `${startDate} ${t('common:at')} ${startTime}`;
  const endString = `${endDate} ${t('common:at')} ${endTime}`;

  if (status === 'active' && router.pathname !== '/outage') {
    router.replace({
      pathname: '/outage',
      query: { details, endString },
    });
  }

  // code below fixes "flash" infinite loop between search/outage pages when user tried to go back from outage page during an outage
  useEffect(() => {
    const handlePopState = ({ url }: { url: string }) => {
      if (url.includes('/outage')) {
        return false;
      }

      return true;
    };

    if (status === 'active') {
      router.beforePopState(handlePopState);
    }

    return () => {
      router.beforePopState(() => true);
    };
  }, [router, status]);

  return (
    <Box
      ref={gridRef}
      data-test-id="search-container-grid"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <WeeklyCalculatorProvider>
        <MobileSearchBar
          handleSetItems={() => setIsInitialSearch(false)}
          showMobileSearch={showMobileSearch}
          setShowMobileSearch={setShowMobileSearch}
        />
      </WeeklyCalculatorProvider>

      {hasMaintentanceData && (
        <MaintenanceBanner
          details={details}
          startAt={startString}
          endAt={endString}
          status={status}
        />
      )}

      <ClickAwayListener onClickAway={() => updateSelectedItem(0)}>
        <Flex
          direction={showMobileMap ? 'column' : 'row'}
          data-test-id="list-map-container-grid"
          display={showMobileSearch ? 'none' : 'flex'}
          minHeight="100%"
          height={{
            base: '100%',
            lg: cart
              ? `calc(100vh - ${topNav + searchBar + cartBar}px)`
              : '100%',
          }}
        >
          {!showFilter && !showMobileSearch && (
            <Flex
              direction={showMobileMap ? 'row' : 'column'}
              overflow="scroll"
              width={{ base: '100%', lg: '544px' }}
            >
              {isMobile && (
                <Flex
                  width="100%"
                  display={{ base: 'flex', lg: 'none' }}
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                  borderBottom="1px"
                  borderColor="gray.400"
                  py="3"
                >
                  <IconButton
                    data-test-id="filter-search"
                    icon={<FilterIcon h={5} w={5} />}
                    variant="outline"
                    sx={{
                      '> span': { marginInlineEnd: { base: 0, lg: '.5rem' } },
                    }}
                    w={{ base: 10, md: 'auto' }}
                    onClick={handleFilterClick}
                    aria-label="filter search"
                  />

                  <Styles.FilterButton
                    leftIcon={<SearchIcon />}
                    data-test-id="filter-button"
                    variant="outline"
                    size="md"
                    onClick={() => setShowMobileSearch(true)}
                    aria-label="search"
                  >
                    {t('common:button.searchForVehicles')}
                  </Styles.FilterButton>
                  {!showMobileMap && (
                    <IconButton
                      data-test-id="filter-map"
                      icon={<MapIcon h={5} w={5} />}
                      variant="outline"
                      aria-label="show map"
                      w={10}
                      onClick={() => setShowMobileMap(true)}
                    />
                  )}
                  {showMobileMap && (
                    <IconButton
                      data-test-id="search-item-view"
                      icon={<ListIcon h={5} w={5} />}
                      variant="outline"
                      aria-label="show list"
                      w={10}
                      onClick={() => setShowMobileMap(false)}
                    />
                  )}
                </Flex>
              )}
              {!showMobileMap && (
                <ItemContainer
                  isLoading={isLoading}
                  showFilter={showFilter}
                  handleFilterClick={handleFilterClick}
                />
              )}
            </Flex>
          )}

          {showFilter && !isLoading && (
            <Flex
              flexDirection="column"
              width={{ base: '100%', lg: '544px' }}
              data-test-id="filter-container"
            >
              <ItemFilter setShowFilter={setShowFilter} />
            </Flex>
          )}

          {/* VEHICLE DETAIL */}
          <Drawer
            closeOnEsc
            blockScrollOnMount={!isDesktop}
            isOpen={showItemDetail}
            placement="bottom"
            size={drawerSize}
            portalProps={{ containerRef: gridRef }}
            onClose={onDrawerClose}
            trapFocus={false}
          >
            <DrawerContent sx={drawerContent}>
              <DrawerHeader sx={Styles.DrawerHeaderSx}>
                {t('search:detail.title')}
              </DrawerHeader>
              <DrawerCloseButton top={10} right={10} />
              <DrawerBody>
                {showItemDetail && (
                  <ItemDetail
                    updateDetailView={updateDetailView}
                    id={detailViewId}
                    isAvailable={Boolean(isAvailable)}
                  />
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          {/* SEARCH SUMMARY */}
          <Drawer
            closeOnEsc
            blockScrollOnMount={false}
            isOpen={showReservation}
            placement="bottom"
            portalProps={{ containerRef: gridRef }}
            size={drawerSize}
            onClose={onDrawerClose}
            trapFocus={false}
          >
            <DrawerContent
              data-test-id="search-summary-drawer"
              sx={drawerContent}
            >
              <DrawerHeader sx={Styles.DrawerHeaderSx}>
                {t('search:summary.title')}
              </DrawerHeader>
              <DrawerCloseButton
                top={10}
                right={10}
                onClick={() => ampli.closeReservationSummary()}
              />
              <DrawerBody>
                <SearchSummary />
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          {(isDesktop || showMobileMap) && (
            <Flex
              flexDirection={showMobileMap ? 'row' : 'column'}
              height="100%"
              boxShadow="inset"
              overflow="hidden"
              width={{ base: '100%', lg: 'auto' }}
              flex="1"
            >
              <ItemMap isLoading={isLoading} />
            </Flex>
          )}
        </Flex>
      </ClickAwayListener>
      {cart && <ItemCart />}
    </Box>
  );
};

export default Search;
