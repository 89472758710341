/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@fluidtruck/core';
// import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useResize } from '@/hooks';

import { ModalGroup } from '../modals';

interface MaintenanceProps {
  details: string;
  startAt: string;
  endAt: string;
  status: 'upcoming' | 'active';
}

export const MaintenanceBanner = ({
  details,
  startAt,
  endAt,
  status,
}: MaintenanceProps) => {
  const [open, setOpen] = useState(false);

  // const { t } = useTranslation(['common', 'maintenance']);
  const { isMobile } = useResize();

  // const modalHeaderSize = isMobile ? 18 : 22;
  const bannerTextSize = isMobile ? 16 : 18;

  // const modalHeight = (isIpad || isDesktop) && 500;

  const handleClick = () => setOpen(prevState => !prevState);

  // const isActive = status === 'active';

  return (
    <>
      <Flex
        style={{
          width: '100%',
          height: isMobile ? 76 : 144,
          cursor: 'pointer',
        }}
        backgroundColor="#FFAC06"
        alignItems="center"
        justifyContent="center"
        onClick={handleClick}
      >
        <Text
          mb={0}
          color="black"
          fontSize={bannerTextSize}
          fontWeight={700}
          p={12}
          textAlign="center"
          textTransform="uppercase"
        >
          Kingbee recently acquired the Fluid Truck App
          <br />
          [Click to learn more]
        </Text>
      </Flex>
      <ModalGroup
        onClose={handleClick}
        size={isMobile ? 'full' : 'md'}
        // bodyProps={{
        //   style: {
        //     minHeight: modalHeight,
        //   },
        // }}
        // headerProps={{
        //   style: {
        //     fontSize: modalHeaderSize,
        //   },
        // }}
        isOpen={open}
        // title={t('maintenance:outage.maintenanceWindowDetails')}
      >
        <Text pt={3}>
          {details}
          Kingbee recently acquired some parts of Fluid Truck, including the app
          and reservation platform. We are actively working on rebranding these
          assets. You may continue to see Fluid&apos;s branding across these
          platforms for some time. Rental agreements in place prior to this
          transaction will be honored, but you will be charged by Kingbee moving
          forward. Disputing these charges may result in additional fees.
        </Text>
      </ModalGroup>
    </>
  );
};
